import "./bootstrap";
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import AlpineRayPlugin from 'alpinejs-ray';
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import calendarPlugin from "@fullcalendar/icalendar";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import Tooltip from "@ryangjchandler/alpine-tooltip";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import Driver from "driver.js";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";
import "photoswipe/style.css";
import flatpickr from "flatpickr";
import { DateTime } from "luxon";
import Sortable from 'sortablejs';
import Trix from "trix";
import 'trix/dist/trix.css';
import Cleave from 'cleave.js';
import '../../vendor/joshhanley/livewire-autocomplete/resources/js/autocomplete';

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint';

window.PhotoSwipeLightbox = PhotoSwipeLightbox;
window.pswpModule = PhotoSwipe;
window.DateTime = DateTime;
window.flatpickr = flatpickr;
window.Driver = Driver;
window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.iCalendarPlugin = calendarPlugin;
window.resourceTimelinePlugin = resourceTimelinePlugin;
window.MapboxStyleSwitcherControl = MapboxStyleSwitcherControl;
import mapboxgl from "mapbox-gl";
window.mapboxgl = mapboxgl;
window.MapboxGeocoder = MapboxGeocoder;
window.MapboxDraw = MapboxDraw;
window.MapboxDrawWaypoint = MapboxDrawWaypoint;
window.Sortable = Sortable;
window.Cleave = Cleave;

import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Alpine.plugin(Clipboard);
Alpine.plugin(Tooltip);

window.Alpine = Alpine;
Alpine.plugin(AlpineRayPlugin);
Livewire.start();
